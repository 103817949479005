.brk-logos__img {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  opacity: 0.2;
  -webkit-transition: 0.3s ease opacity;
  transition: 0.3s ease opacity; }

.brk-logos__item-l-t-angle {
  position: absolute;
  left: -1px;
  top: 0;
  width: 50px;
  height: 50px;
  -webkit-transition: 0.3s ease border-color;
  transition: 0.3s ease border-color;
  border-top: 1px solid #919cb4;
  border-left: 1px solid #919cb4; }

.brk-logos__item-r-t-angle {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  top: 0;
  -webkit-transition: 0.3s ease border-color;
  transition: 0.3s ease border-color;
  border-top: 1px solid #919cb4;
  border-right: 1px solid #919cb4; }

.brk-logos__item-r-b-angle {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: -1px;
  right: 0;
  -webkit-transition: 0.3s ease border-color;
  transition: 0.3s ease border-color;
  border-right: 1px solid #919cb4;
  border-bottom: 1px solid #919cb4; }

.brk-logos__item-l-b-angle {
  position: absolute;
  width: 50px;
  height: 50px;
  left: -1px;
  bottom: -1px;
  -webkit-transition: 0.3s ease border-color;
  transition: 0.3s ease border-color;
  border-bottom: 1px solid #919cb4;
  border-left: 1px solid #919cb4; }

.brk-logos__item:hover .brk-logos__img {
  opacity: 1; }

.brk-logos__item:hover .brk-logos__item-l-t-angle {
  border-color: rgba(var(--white-rgb), 1);
  z-index: 1; }

.brk-logos__item:hover .brk-logos__item-r-t-angle {
  border-color: rgba(var(--white-rgb), 1);
  z-index: 1; }

.brk-logos__item:hover .brk-logos__item-r-b-angle {
  border-color: rgba(var(--white-rgb), 1);
  z-index: 1; }

.brk-logos__item:hover .brk-logos__item-l-b-angle {
  border-color: rgba(var(--white-rgb), 1);
  z-index: 1; }

.brk-logos__item::after {
  content: "";
  display: block;
  padding-top: 71.5%; }

.brk-bottom-m80 {
  position: relative;
  bottom: -80px; }

.brk-slider-cards .slick-slide {
  margin: 0 5px; }

.brk-slider-cards .slick-list {
  margin: 0 -5px; }

.brk-slider-cards .brk-slider__control {
  position: absolute;
  bottom: -88px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center; }
  .brk-slider-cards .brk-slider__control .brk-slider__control-prev,
  .brk-slider-cards .brk-slider__control .brk-slider__control-next {
    font-size: 26px;
    padding: 10px;
    cursor: pointer;
    -webkit-transition: .3s ease transform;
    transition: .3s ease transform;
    color: var(--brk-base-3); }
    .brk-slider-cards .brk-slider__control .brk-slider__control-prev.slick-disabled,
    .brk-slider-cards .brk-slider__control .brk-slider__control-next.slick-disabled {
      opacity: 0.2;
      pointer-events: none; }
    .brk-slider-cards .brk-slider__control .brk-slider__control-prev:hover,
    .brk-slider-cards .brk-slider__control .brk-slider__control-next:hover {
      -webkit-transform: scale(1.3);
              transform: scale(1.3); }
  .brk-slider-cards .brk-slider__control .brk-slider__control-prev {
    -webkit-box-ordinal-group: 2;
            order: 1;
    margin-right: 16px; }
  .brk-slider-cards .brk-slider__control .brk-slider__control-next {
    -webkit-box-ordinal-group: 4;
            order: 3;
    margin-left: 16px; }
  .brk-slider-cards .brk-slider__control .brk-slider__dots {
    -webkit-box-ordinal-group: 3;
            order: 2;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .brk-slider-cards .brk-slider__control .brk-slider__dots li {
      width: 12px;
      height: 12px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center;
      cursor: pointer;
      margin-left: 8px;
      margin-right: 8px; }
      .brk-slider-cards .brk-slider__control .brk-slider__dots li button {
        font-size: 0;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        -webkit-transition: 0.3s ease background-color, 0.3s ease width, 0.3s ease height;
        transition: 0.3s ease background-color, 0.3s ease width, 0.3s ease height;
        background-color: var(--brk-base-3); }
      .brk-slider-cards .brk-slider__control .brk-slider__dots li:first-child {
        margin-left: 0;
        margin-right: 5px; }
      .brk-slider-cards .brk-slider__control .brk-slider__dots li:last-child {
        margin-right: 0;
        margin-left: 5px; }
    .brk-slider-cards .brk-slider__control .brk-slider__dots li.slick-active button {
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1px solid rgba(var(--brk-base-3-rgb), 0.3); }

.brk-tabs__title-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column; }
  .brk-tabs__title-container .divider-cross {
    margin-top: 0;
    margin-bottom: 5px; }
    .brk-tabs__title-container .divider-cross span {
      background-color: var(--brk-base-5); }
    .brk-tabs__title-container .divider-cross:before, .brk-tabs__title-container .divider-cross:after {
      background-color: rgba(230, 230, 230, 0.1); }

.brk-tabs__title {
  color: #fff;
  margin-bottom: 24px; }

.brk-tabs__title-decorator {
  position: relative; }
  .brk-tabs__title-decorator::before, .brk-tabs__title-decorator::after {
    content: "";
    width: 65px;
    height: 1px;
    position: absolute;
    top: 6px; }
  .brk-tabs__title-decorator::before {
    left: -webkit-calc(50% - 86px);
    left: calc(50% - 86px); }
  .brk-tabs__title-decorator::after {
    right: -webkit-calc(50% - 83px);
    right: calc(50% - 83px); }
